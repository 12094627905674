<template>
	<div class="el-aside-container">
		<div class="el-aside-logo">
			易业油联
			<div class="m-version">VUE版 v0.0.3</div>
		</div>
		<div class="el-aside-group -scroll-y">
			<el-aside :width="asideWidth">

				<el-menu v-for="(item0, index0) in routes" :index="item0.name" :default-active="menuActiveName" background-color="#233042" text-color="#d6e9f3">
					
					<!-- 根据顶部选项-匹配当前模块 -->
					<template v-if="index0==moduleIndex" v-for="(item1, index1) in item0.children">
						
						<!-- 一级项目（条目） -->
						<el-menu-item v-if="!item1.children" :index="item1.name" @click="forwrad(item1)">
							<el-icon v-if="item1.icon" :class="item1.icon"></el-icon>
							<span>{{item1.title}}</span>
						</el-menu-item>
						<!-- 一级菜单（分组） -->
						<el-sub-menu v-else :index="item1.name">
							<!-- 分组标题 -->
							<template #title>
								<el-icon v-if="item1.icon" :class="item1.icon"></el-icon>
								<span>{{item1.title}}</span>
							</template>
							
							<!-- 循环二级项目 -->
							<div v-for="(item2, index2) in item1.children">
								<!-- 二级项目（条目） -->
								<el-menu-item v-if="!item2.children" :index="item2.name" @click="forwrad(item2)">
									<el-icon v-if="item2.icon" :class="item2.icon"></el-icon>
									<span>{{item2.title}}</span>
								</el-menu-item>
								<!-- 二级菜单（分组） -->
								<el-sub-menu v-else :index="item2.name">
									<!-- 分组标题 -->
									<template #title>
										<el-icon v-if="item2.icon" :class="item2.icon"></el-icon>
										<span>{{item2.title}}</span>
									</template>
									<!-- 三级菜单（条目） -->
									<el-menu-item v-for="(item3, index3) in item2.children" :index="item2.name" @click="forwrad(item3)">
										<el-icon v-if="item3.icon" :class="item3.icon"></el-icon>
										<span>{{item3.title}}</span>
									</el-menu-item>
								</el-sub-menu>
							</div><!-- End Second For -->
						</el-sub-menu><!-- End First Menu -->
					</template><!-- End Top Group Menu -->
				</el-menu><!-- End Menu -->
			</el-aside>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions} from 'vuex'
import routes from '@/router/routes'

export default {
	props: ['foldAside'],
	data() {
		return {
			asideWidth: '200px',
			moduleIndex: 'index', //当前模块
			menuActiveName: ''
		}
	},
	computed: {
		// ...mapState('common', ['menuActiveName', 'topMenuActiveName', 'mainTabs', 'dynamicRoutes'])
	},
	methods: {
		// ...mapActions('common', ['updateMenuActiveName', 'updateMainTabs', 'updateMainTabsActiveName'])
		
		//跳转
		forwrad: function(item){
			//页面跳转
			if(item.path) {
				this.$router.push({ path: item.path, query: item.query})
			}
			//调用路由中设置的函数
			else if(item.call) {
				item.call(this, item)
			}
			//调用本界面的函数
			else if(item.self) {
				this[item.self](item)
			}
			
		}
	},
    created() {
			this.routes = routes
			
		
			
            // console.log(this._dynamicRoutes.grid);
            // setInterval(()=>{
            //     console.log('topMenuActiveName : ',this.topMenuActiveName);
            //     console.log('menuActiveName : ',this.menuActiveName);
            // }, 2000)
    },
	watch: {
		// 监视是否折叠侧边栏，折叠则宽度为 64px。
		foldAside(val) {
			this.asideWidth = val ? '200px' : '64px'
			this.iconSize = val
		},
        //监听顶部菜单变化
        topMenuActiveName: function(val){
            console.log('===Change topMenuActiveName : ',val)
        },
        //监听顶部菜单变化
        menuActiveName: function(val){
            console.log('===Change menuActiveName : ',val)
        },
		// 监视路由的变化，每次点击菜单项时会触发
		$route(route) {
			// 路由变化时，修改当前选中的菜单项
			// this.updateMenuActiveName(route.name)
			// 是否显示标签页
			return
			if (route.meta.isTab) {
				// 判断当前标签页数组中是否存在当前选中的标签，根据标签名匹配
				let tab = this.mainTabs.filter(item => item.name === route.name)[0]
				// 若当前标签页数组不存在该标签，则向数组中添加标签
                console.log('route : ',route);
                console.log('route : ',route.meta);
				if (!tab) {
					// 设置标签页数据
					tab = {
						name: route.name,
						path: route.path,
						title: route.meta.title,
						params: route.params,
						query: route.query,
						type: isURL(route.meta.iframeUrl) ? 'iframe' : 'module',
						iframeUrl: route.meta.iframeUrl || ''
					}
					// 将数据保存到标签页数组中
					this.updateMainTabs(this.mainTabs.concat(tab))
				}
				// 保存标签页中当前选中的标签名
				this.updateMainTabsActiveName(route.name)
			}
		}
	}
}
</script>

<style>

</style>
